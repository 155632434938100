import React, {Component, ReactNode} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import {Translation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {PRESS_ALIAS} from '../../constants/constants';
import {getCategoryLink} from '../../helpers/getLinks';
import {ICategory} from '../../types/types';

import './Menu.css';

export interface IMenuProps extends IClassNameProps {
    categoriesData: ICategory[];
    page: string;
    categoryAlias?: string;
    onClose(): void;
}

const menu: ClassNameFormatter = cn('Menu');

export class Menu extends Component<IMenuProps> {
    scrollToMusic = () => {
        setTimeout(() => {
            window.location.href = '/#music';
        }, 0);
        this.props.onClose();
    };

    getMenuItem = (category: ICategory, index: number): ReactNode => {
        const linkToCategory = getCategoryLink(category.alias);

        const active = (category.alias === this.props.categoryAlias);

        return (
            <li className={menu('Item', { type: category.alias, active })} key={index}>
                <Link
                    onClick={this.props.onClose}
                    className={classnames(menu('Link'), menu('ItemLink'))}
                    to={linkToCategory}
                >
                    <Translation>
                        {
                            (t) =>
                                <div
                                    className={menu('ItemLink-Text')}>{t(category.name)}</div>
                        }
                    </Translation>
                </Link>
            </li>
        )
    };

    getMenuList = (data: ICategory[]): ReactNode => {
        const newCategoriesList: ICategory[] = [];

        data.map((category: ICategory) => {
            if (category.alias === PRESS_ALIAS) {
                newCategoriesList.unshift(category);
            } else {
                newCategoriesList.push(category);
            }
        });

        return (
            <>
                {newCategoriesList.slice(0, 2).map((category: ICategory, index) => {
                    return this.getMenuItem(category, index);
                })}
                <li className={menu('Item', { type: 'music' })}>
                    <Link
                        to={'/'}
                        onClick={this.scrollToMusic}
                        className={classnames(menu('Link'), menu('ItemLink'))}
                    >
                        <Translation>
                            {
                                (t) =>
                                    <div className={menu('ItemLink-Text')}>{t('Музыка')}</div>
                            }
                        </Translation>
                    </Link>
                </li>
                {newCategoriesList.slice(2).map((category: ICategory, index) => {
                    return this.getMenuItem(category, index);
                })

                }
            </>
        )
    };

    render() {
        const { categoriesData, page, categoryAlias, className } = this.props;

        return (
            <div className={
                classnames(
                    menu(),
                    menu({ ofPage: page }),
                    menu({ theme: categoryAlias }), className)}>
                <div className={menu('Wrapper')}>
                    <ul className={menu('List')}>
                        {this.getMenuList(categoriesData)}
                    </ul>
                </div>
            </div>
        );
    }
}
