import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import {Link} from 'react-router-dom';
import MediaQuery from 'react-responsive';

import {ICategory, Languages, PageType} from '../../types/types';
import {CloseButton} from '../Buttons/CloseButton/CLoseButton';
import {HamburgerButton} from '../Buttons/HumburgerButton/HumburgerButton';
import { ReactComponent as LogoIconSVG } from '../Icons/logo-color.svg';
import { ReactComponent as BackButtonSVG } from '../Icons/backBtn.svg';
import {Menu} from '../Menu/Menu';

import './Header.css';
import {getCategoryLink} from "../../helpers/getLinks";
import {getCategoryTitleText} from "../../helpers/getCategoryTitleText";

export interface IHeaderState {
    open: boolean;
    selectOpen: boolean;
}

export interface IHeaderDispatchProps {
    onChange(lang: Languages): void;
}

export interface IHeaderStateProps {
    page: string;
    categoriesData: ICategory[];
    categoryAlias?: string;
    language: string;
    whiteTheme?: boolean;
}

export interface IHeaderProps extends IClassNameProps, IHeaderStateProps, IHeaderDispatchProps {
}

const header: ClassNameFormatter = cn('Header');

export class Header extends Component <IHeaderProps, IHeaderState> {
    state: IHeaderState = {
        open: false,
        selectOpen: false,
    };

    toggleMenu = () => this.setState({open: !this.state.open});
    closeMenu = () => this.setState({open: false});

    changeLang = (lang: Languages) => {
        const {onChange} = this.props;

        onChange(lang);
        this.setState({selectOpen: false});

    }

    renderLogo = () => {
        return (
            <div className={header('LogoContainer')}>
                <Link to='/' className={header('Link')} onClick={this.closeMenu}>
                    <LogoIconSVG/>
                </Link>
            </div>
        )
    }

    renderBackLink = () => {
        const {categoryAlias} = this.props;
        return (
            <div className={header('HomeLinkWrap')} onClick={() => history.back()}>
                <div className={header('HomeLinkIcon')}>
                    <BackButtonSVG />
                </div>
                <h2 className={classnames(header('Title'), header('type_' + categoryAlias))}>
                    {categoryAlias && getCategoryTitleText(categoryAlias)}
                </h2>
            </div>
        )
    }

    renderLogoOrBackLink = () => {
        const {categoriesData, page, categoryAlias, language, whiteTheme} = this.props;

        if (this.state.open) {
            return this.renderLogo();
        } else {
            if ((page === PageType.typeCategory) || (page === PageType.typeContent)) {
                return this.renderBackLink();
            } else {
                return this.renderLogo();
            }
        }
    };

    render() {
        const {open, selectOpen} = this.state;
        const {categoriesData, page, categoryAlias, language, whiteTheme} = this.props;

        return (
            <header
                className={classnames(
                    header({ofPage: page, theme: categoryAlias, visible: open}),
                    this.props.className)}>
                <div className={classnames(header('Wrapper'), 'Wrapper')}>
                    <MediaQuery minDeviceWidth={1200}>
                        {(matches: boolean) => matches ? this.renderLogo() : this.renderLogoOrBackLink()}
                    </MediaQuery>
                    <div className={header('ButtonWrapper')}>
                        <HamburgerButton onClick={this.toggleMenu}
                                         className={
                            classnames(header('HamburgerButton', {visible: !this.state.open}))}/>
                        <CloseButton onClose={this.toggleMenu}
                                     className={
                            classnames(header('CloseButton', {visible: this.state.open}))}/>
                    </div>
                    <Menu
                        className={classnames(header('Menu', {visible: open}))}
                        page={page}
                        categoriesData={categoriesData}
                        categoryAlias={categoryAlias}
                        onClose={this.closeMenu}
                    />
                </div>
            </header>
        );
    }
}
