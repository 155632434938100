import React, {Component, ReactNode} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {Translation} from 'react-i18next';
import {Link} from 'react-router-dom';

import { ReactComponent as LogoIconSVG } from '../Icons/logo-color.svg';

import './ErrorPage.css';

export class ErrorPage extends Component <ReactNode> {
    render() {
        const errorPage: ClassNameFormatter = cn('ErrorPage');

        return (
            <div className={errorPage()}>
                <div className={errorPage('LogoContainer')}>
                    <Link to='/' className={errorPage('LogoLink')}>
                        <LogoIconSVG />
                    </Link>
                </div>
                <Translation>
                    {
                        (t, {i18n}) =>
                        <h1 className={errorPage('Title')}>{t('Что-то')}
                            <span>{t('пошло не так')}...</span>

                        </h1>
                    }
                </Translation>
                <Translation>
                    {
                        (t, {i18n}) =>
                        <Link to='/' className={errorPage('Link')}>{t('На главную')}</Link>
                    }
                </Translation>
            </div>
        )
    }
}
