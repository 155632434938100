import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import {Link} from 'react-router-dom';

import {PRESS_ALIAS} from '../../../constants/constants';
import {getJournalTitleByDate} from '../../../helpers/getJournalTitleByDate';
import {getContentLink} from '../../../helpers/getLinks';
import {IContentInfo, IPreview} from '../../../types/types';
import { ReactComponent as Cart } from '../../Icons/cart.svg';

import './PressContentItem.css';

const pressContentItem: ClassNameFormatter = cn('PressContentItem');

export interface IPressContentItemStateProps {
    language: string;
    authorised?: boolean;
}

export interface IPressContentItemProps extends IClassNameProps, IPressContentItemStateProps {
    content: IContentInfo;
    isSlide: boolean;
}

export class PressContentItem extends Component<IPressContentItemProps> {
    render() {
        const {content, language, authorised, isSlide} = this.props;
        const month = getJournalTitleByDate(content, language);
        const link = getContentLink(PRESS_ALIAS, content.id);
        const preview = content.previews.filter((preview: IPreview) => preview.type.includes('normal'));

        const cartIcon = authorised ? null :
            (
                <div className={pressContentItem('Cart')}>
                    <Cart/>
                </div>
            );

        return (
            isSlide ? (
                <Link
                    to={link}
                    className={classnames(pressContentItem({premium: !authorised, isSlide}), this.props.className)}>
                    <div className={pressContentItem('ImageWrap')}>
                        {cartIcon}
                        <img data-src={`/storage/d/${preview[0].link}`}
                             className={classnames('swiper-lazy', pressContentItem('Image'))}/>
                        <div className='swiper-lazy-preloader'/>
                    </div>
                    <p className={pressContentItem('Title')}>{month}</p>
                </Link>
            ) : (
                <Link to={link} className={classnames(pressContentItem({premium: !authorised}),  this.props.className)}>
                    <div className={pressContentItem('ImageWrap')}>
                        <img src={`/storage/d/${preview[0].link}`}
                             className={classnames(pressContentItem('Image'))}/>
                    </div>
                    <p className={pressContentItem('Title')}>{month}</p>
                </Link>
            )
        )
    }
}
