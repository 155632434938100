import React, {Component, ReactNode} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {classnames} from '@bem-react/classnames';
import {IClassNameProps} from '@bem-react/core';
import { Translation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import {Link} from 'react-router-dom';

import {getContentLink} from '../../helpers/getLinks';
import {ContentType, IPreview, ITag} from '../../types/types';
import {ContentDescription} from '../ContentDescription/ContentDescription';
import { ReactComponent as Cart } from '../Icons/cart.svg';
import play from '../Icons/play-button.svg';

import './ContentItemPreview.css';

export interface IContentItemPreviewStateProps {
    authorised?: boolean;
}

export interface IContentItemPreviewProps extends IClassNameProps, IContentItemPreviewStateProps {
    id: number;
    title: string;
    description?: string;
    previews: IPreview[];
    type: ContentType.type1 | ContentType.type2 | ContentType.type3 | ContentType.type4;
    tags: ITag[];
    key: number;
    order: number;
    url: string;
    categoryAlias: string;
    page: string;
    uidx: string;
    size: string;
    theme?: string;
}

const contentItemPreview: ClassNameFormatter = cn('ContentItemPreview');

export class ContentItemPreview extends Component<IContentItemPreviewProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getContentType = (contentType: string): ReactNode => {
        if (contentType === ContentType.type1) {
            return (
                <Translation>
                    {
                        (t, { i18n }) =>
                            <div>{t('Читать далее')}</div>
                    }
                </Translation>
            );
        } else if (contentType === ContentType.type2 || contentType === ContentType.type4) {
            return (
                <Translation>
                    {
                        (t, { i18n }) =>
                            <div>{t('Смотреть видео')}</div>
                    }
                </Translation>
            );
        } else {
            return null;
        }
    };

    getIcon = (contentType: string): string => {
        if (contentType === ContentType.type1) {
            return 'read'
        } else if (contentType === ContentType.type2 || contentType === ContentType.type4) {
            return 'video'
        } else {
            return '';
        }
    };

    renderButton = () => {
        const {type, authorised, size} = this.props;
        const icon = this.getIcon(type);
        let isVideo = false;
        if (type === ContentType.type2 || type === ContentType.type4) {
            isVideo = true;
        }

        const cartIcon = authorised ? null :
            (
                <div className={contentItemPreview('Cart')}>
                    <Cart/>
                </div>
            );

        return (
            <div className={classnames(contentItemPreview('ButtonWrapper', {size}))}>
                <div className={classnames(contentItemPreview('Button', {type: icon}))}>
                    <div
                        className={contentItemPreview('ButtonText')}>
                        {isVideo ?
                            (`Смотреть ${size !== 'small' ? 'видео' : ''}`) :
                            (`Читать ${size !== 'small' ? 'дальше' : ''}`)
                        }
                    </div>
                </div>
                {icon === 'video' ? cartIcon : null}
            </div>
        )
    };

    render() {
        const {tags, type, order, id, categoryAlias, previews, title, page, theme, authorised, size} = this.props;
        const contentType = this.getContentType(type);
        const icon = this.getIcon(type);
        let preview;
        previews[1] ? preview = previews[1] : preview = previews[0];
        const contentLink = getContentLink(categoryAlias, id);
        let isVideo = false;
        if (type === ContentType.type2 || type === ContentType.type4) {
            isVideo = true;
        }

        if (preview) {
            const itemStyle = {
                backgroundImage: `url(/storage/d/${preview.link})`,
                backgroundSize: 'cover',
                backgroundColor: '#dbdbdb',
                backgroundPositionY: '50%, 50%',
                backgroundPositionX: '50%, 50%',
                backgroundRepeat: 'no-repeat',
            };

            return (
                <div
                    className={classnames(
                        contentItemPreview({
                            type,
                            ofPage: page,
                            premium: !authorised,
                            size,
                            order: String(order),
                            theme,
                        }),
                        this.props.className)}>
                    <Link to={contentLink} className={contentItemPreview('Link')}>
                        <div className={contentItemPreview('Info')}>
                            <div className={contentItemPreview('ImageWrap')}>
                                {(size !== 'small') ? this.renderButton() : null}
                                <LazyLoad scroll={true}>
                                    <div style={itemStyle} className={contentItemPreview('Image')}>
                                        {isVideo ? (
                                            <div className={contentItemPreview('PlayIcon')} />
                                            ) : null
                                            }
                                    </div>
                                </LazyLoad>
                            </div>
                            <div className={contentItemPreview('DescriptionWrap')}>
                                <ContentDescription className={contentItemPreview('Description')}
                                                    tags={tags} title={title}
                                                    link={contentLink} type={type} size={size} page={page}/>
                                {(size === 'small') ? this.renderButton() : null}
                            </div>

                        </div>
                    </Link>
                </div>
            )
        }

        return null;
    }
}
