import {PRESS_ALIAS} from '../constants/constants';

export function getCategoryTitleText(alias: string) {
    switch (alias) {
        case 'pressa':
            return 'Журналы';
        case 'sport':
            return 'Спорт';
        case 'avto':
            return 'Авто';
        case 'stil':
            return 'Стиль';
        case 'gadzheti':
            return 'Гаджеты';
        case 'otdikh':
            return 'Отдых';
        case 'kosmos':
            return 'Космос';
        case 'pitanie':
            return 'Питание';
    }
}
